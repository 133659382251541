import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { Fragment, useState } from 'react';
import './Header.scss';
import logoIcon from '../../ui/img/logoNew.png';
import NavigationDrawer from './NavigationDrawer/NavigationDrawer';
import { Instagram, Whatsapp } from 'react-bootstrap-icons';

//..GatsbyImageSharpFluid_noBase64 for no blur up effect
const fetchLogoQuery = graphql`
    query {
        file(relativePath: { eq: "logos/logo_placeholder.png" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;

const Header: React.FC = () => {
    const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
    const image = useStaticQuery(fetchLogoQuery);

    const toggleNavDrawer = () => {
        isNavDrawerOpen ? setNavDrawerOpen(false) : setNavDrawerOpen(true);
    };

    return (
        <Fragment>
            <header className="header">
                <div
                    className="header__hamburger-icon"
                    onClick={() => toggleNavDrawer()}
                >
                    <svg width="20" height="15">
                        <rect fill="#f4a444" width="20" height="3"></rect>
                        <rect fill="#f4a444" y="6" width="20" height="3"></rect>
                        <rect
                            fill="#f4a444"
                            y="12"
                            width="20"
                            height="3"
                        ></rect>
                    </svg>
                </div>
                <div className="header__item">
                    <Link className="logo-item" to="/">
                        
              <div className="logo-title">
              <img src={logoIcon} loading="lazy" alt="Logo"
              className="logo"/>
              </div>
                    </Link>
                </div>
                <nav className="header__item nav">
                <div className="nav__item">
                        <Link to="/">Home</Link>
                    </div>
                    <div className="nav__item">
                        <Link to="/about/">About Us</Link>
                    </div>
                    <div className="nav__item dropdown">
                        <div className="nav__item__link">
                            <>Services</>
                            <div className="nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-1/">Printing</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-2/">Advertising Stands</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-3/">Promotional Items</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-4/">Outdoor Signages</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="nav__item">
                        <Link to="/contact/">Contact</Link>
                    </div>
                   
                </nav>
                <div className="socials">
                <a href='https://wa.me/+254713081707?'>
                <Whatsapp color="#3f3a64" size={36} />
                </a>
                <a href='https://www.instagram.com/ohlbrands?igsh=N2tsOW1zbnJyOWR2'>
                <Instagram color="#3f3a64" size={36} />
                </a>
                </div>

                {/* <div className="header__item search-nav">
                    <SearchBox size={Size.small} />
                </div>
                <div className="header__item newsletter-nav">
                    <Button
                        accent={Accent.primary}
                        type={ButtonType.linkInternal}
                        onClickLink=""
                        className="newsletter-nav__button"
                    >
                        Newsletter
                    </Button>
                </div> */}
            </header>
            <NavigationDrawer
                isNavDrawerOpen={isNavDrawerOpen}
                onToggleNavDrawer={toggleNavDrawer}
            />
        </Fragment>
    );
};

export default Header;
