import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../ui/Footer/Footer';
import Header from '../../ui/Header/Header';
import './PageLayout.scss';
import AnnouncementHeader from '../../ui/AnnouncementHeader/AnnouncementHeader';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface PageLayoutProps {
    children?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = (props: PageLayoutProps) => {
    return (
        <Fragment>
            {/* Set the favicon */}
            <Helmet>
                <link rel="icon" type="image/png" href="../../ui/img/logoicon.png" />
            </Helmet>

            <div className="page">
                <Header />
                <AnnouncementHeader />
                {props.children}
                <Footer />
            </div>
        </Fragment>
    );
};

export default PageLayout;
