import React from 'react';
import { Link } from 'gatsby';
import './AnnouncementHeader.scss';

interface AnnouncementHeaderProps {
    className?: string;
    children?: React.ReactNode;
}

//hardcoded it to avoid copy pasting everywhere
const AnnouncementHeader: React.FC<AnnouncementHeaderProps> = (
    props: AnnouncementHeaderProps
) => {
    return (
        <div className={props.className || 'announcement-header'}>
            <div className="announcement-header__content">
            <nav className="announcement-nav">
                    <div className="announcement-nav__item dropdown">
                        <div className="announcement-nav__item__link">
                            <Link to="/topics/topic-1/">Printing</Link>
                            <div className="announcement-nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post/">Flyers</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-2/">Brochures</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-3/">Catalogues</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-4/">Business Cards</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-5/">Letter Heads</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-6/">Notebooks</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-7/">Calendars</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-8/">Wedding Cards</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-9/">Staff Cards</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-10/">Envelopes</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-11/">Annual Reports</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-12/">Certificates</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-13/">Stamps</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-14/">Company Seals</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-15/">Invoices/ Delivery Notes</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-16/">Receipt Books</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-17/">Vouchers</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-18/">Booklets</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="announcement-nav__item dropdown">
                        <div className="announcement-nav__item__link">
                            <Link to="/topics/topic-2/">Advertising Stands</Link>
                            <div className="announcement-nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-19/">Roll-Up Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-20/">Telescopic Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-21/">Teardrops</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-22/">X-Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-23/">Backdrop Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-24/">Doorstand Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-25/">Mesh Banners</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-26/">Flag Printing</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-27/">Tent Branding</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-28/">Books Branding</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-29/">Wheel Covers</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-30/">Wall Branding</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-31/">Brochures Holders</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-32/">Snapper Frames</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="announcement-nav__item dropdown">
                        <div className="nav__item__link">
                            <Link to="/topics/topic-3/">Promotional Items</Link>
                            <div className="nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-33/">T-Shirts</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-34/">Polo-Shirts</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-35/">Staff Uniform</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-36/">Pens</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-37/">Mugs</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-38/">Keyholders</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-39/">Mouse Pads</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-40/">Wristbands</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-41/">Water Bottles</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-42/">Dust Coats</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-43/">Diaries</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-44/">Caps</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-45/">Lanyards</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-46/">Reflectors</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-47/">Helmets</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-48/">Hoodies</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-49/">Umbrellas</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="announcement-nav__item dropdown">
                        <div className="announcement-nav__item__link">
                            <Link to="/topics/topic-4/">Outdoor Signages</Link>
                            <div className="announcement-nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="announcement-dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-50/">Large Formats</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-51/">Digital Flex Printing</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-52/">Flex Board</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-53/">USB Signages</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-54/">Name Plates</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-55/">Wall Branding</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-56/">In-Shop Merchandising</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-57/">Canopy</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-58/">Cloth Banner Printing</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-59/">3D/2D Signages</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-60/">Eco-Solvent Printing</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link className="ann-dropdown-link" to="/example-post-61/">Light Box Signs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </nav>
            </div>
        </div>
    );
};

export default AnnouncementHeader;
